<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="fill-black"
  >
    <g clip-path="url(#clip0_2505_10749)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.8 0H11.2C13.84 0 16 2.16 16 4.8V11.2C16 13.84 13.84 16 11.2 16H4.8C2.16 16 0 13.84 0 11.2V4.8C0 2.16 2.16 0 4.8 0ZM12.24 2.96C11.76 2.96 11.28 3.36 11.28 3.92C11.28 4.48 11.68 4.88 12.24 4.88C12.8 4.88 13.2 4.48 13.2 3.92C13.12 3.36 12.72 2.96 12.24 2.96ZM8 4.16C5.84 4.16 4.08 5.92 4.08 8.08C4.08 10.24 5.84 12 8 12C10.16 12 11.92 10.24 11.92 8.08C12 5.92 10.24 4.16 8 4.16ZM8 10.64C6.64 10.64 5.44 9.52 5.44 8.08C5.44 6.72 6.56 5.52 8 5.52C9.36 5.52 10.56 6.64 10.56 8.08C10.56 9.52 9.44 10.64 8 10.64Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2505_10749">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
